import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../api/constants";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import ReceiptImg from "./Img";

interface Receipt {
    id: string
    name: string
}

const ReceiptList = () => {
    const [receipts, setReceipts] = useState<Receipt[]>([]);
    const auth = useAuth()
    function delete23(receipt: string): void {
        const access_token = auth.user?.access_token
        axios
            .delete(`${API_URL}/api/receipts/${receipt}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
                const index = receipts.findIndex(receipt2 => receipt2.name === receipt);
                console.log(index);
                setReceipts(receipts.splice(index, 1));
            })
    }

    useEffect(() => {
        const access_token = auth.user?.access_token
        axios
            .get<Receipt[]>(`${API_URL}/api/receipts/todo`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                    // "Content-Type": "application/json",
                    // "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setReceipts(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {receipts.length === 0 && (
                <div className="text-center">
                    <h2>Receipts found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Id</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {receipts && receipts.map(receipt => {
                                return <tr key={receipt.id}>
                                    <td>{receipt.name}</td>
                                    <td><ReceiptImg receipt={receipt}/></td>
                                    <td>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-group" style={{ marginBottom: "20px" }}>
                                                <Link to={`/receipts/${receipt.id}/move`} className="btn btn-sm btn-outline-secondary">MOVE</Link>
                                                <button className="btn btn-sm btn-outline-secondary" onClick={() => delete23(receipt.id)}>Delete</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ReceiptList;