import React, {useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {API_URL} from "../../api/constants";
import {useAuth} from "react-oidc-context";

interface ISearch {
    name: string;
    op: string
    value: string
}

interface TransactionGroupSearchProps {
    process: Function
}

const TransactionGroupSearch = (props : TransactionGroupSearchProps) => {
    const [search, setSearch] = useState<ISearch[]>([]);
    const auth = useAuth();

    function handleInputChanges(e: React.FormEvent<HTMLInputElement>): void {
        e.preventDefault();

        let jsonArrayObject: ISearch[];
        jsonArrayObject=[{
            name: e.currentTarget.name,
            op: "~",
            value: "%" + e.currentTarget.value + "%",
        }]
        jsonArrayObject.push()

        setSearch(jsonArrayObject)
    }

    function processSearchSubmission (e: React.FormEvent<HTMLFormElement>): void {
        const access_token = auth.user?.access_token;
        e.preventDefault();
        axios.defaults.headers.get['Accept'] = 'application/hal+json'
        let jsonString = encodeURIComponent(JSON.stringify(Object.values(search)));
        axios
            .get(`${API_URL}/api/transactions/groups/spec2?search=${jsonString}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                },
            })
            .then(data => {
                props.process(data.data)
            })
    }

    return (
        <div>
            <form id={"create-post-form"} onSubmit={processSearchSubmission} noValidate={true}>
                <input name="description" type="text" onChange={(e) => handleInputChanges(e)}/>
                <button className="btn btn-sm btn-outline-secondary" type="submit">Search</button>
            </form>
        </div>
    )
}
export default TransactionGroupSearch;
