import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "react-oidc-context";

const oidcConfig = {
    authority: "https://accounts.deltacore.pl/realms/my-realm",
    client_id: "my-todo-list",
    redirect_uri: window.location.href,
    onSigninCallback: () => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
    }
}

ReactDOM.render(
    <Router>
        <AuthProvider {...oidcConfig}>
            <App />
        </AuthProvider>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
