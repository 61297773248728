import { Link } from "react-router-dom";
import AuthBar from "./AuthBar";
import * as React from "react";

export function Navbar() {
    return (
        <div>
            <AuthBar />
            <nav>
                <ul>
                    <li>
                        <Link to={'/'}> Home </Link>
                    </li>
                    <li>
                        <Link to={'/expense/expense'}> ExpenseList </Link>
                    </li>
                    <li>
                        <Link to={'/expense/group'}> ExpenseGroup </Link>
                    </li>
                    <li>
                        <Link to={'/expense/item'}> ExpenseItem </Link>
                    </li>
                    <li>
                        <Link to={'/transaction/group'}> TransactionList </Link>
                    </li>

                    <li>
                        <Link to={'/widget1'}> Widget </Link>
                    </li>

                    <li>
                        <Link to={'/widget2'}> Widget2 </Link>
                    </li>

                    <li>
                        <Link to={'/widget3'}> Widget3 </Link>
                    </li>

                    <li>
                        <Link to={'/widget4'}> Widget4 </Link>
                    </li>

                    <li>
                        <Link to={'/receipts'}> ReceiptsList </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
