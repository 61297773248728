import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface Receipt {
    id: string
    name: string
}

interface ReceiptImgProps {
    receipt: Receipt
}

const ReceiptImg = (props : ReceiptImgProps) => {
    const [img, setImg] = useState<string>();
    const auth = useAuth()

    useEffect(() => {
        const access_token = auth.user?.access_token
        axios
            .get<string>(`${API_URL}/api/receipts/${props.receipt.id}/download?file=${props.receipt.name}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                    // "Content-Type": "application/json",
                    // "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(new Buffer(response.data).toString('base64'));
                // const data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
                // const data = `data:image/jpeg;charset=utf-8;base64,${new Buffer(response.data).toString('base64')}`;
                const data = `data:image/jpeg;base64,${response.data}`;
                setImg(data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, [auth.user?.access_token, props.receipt]);

    return (
        <div>
            <img width="500px" src={img} alt={props.receipt.name} />
        </div>
    )
}
export default ReceiptImg;
