import React, {useEffect, useState} from "react";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {API_URL} from "../../../api/constants";
import AutoComplete, {IData} from "../../autocomplete/Autocomplete";
import {useAuth} from "react-oidc-context";
import ReceiptImg from "../../receipts/Img";

interface Transaction {
    value: number;
    id: number;
    date: string;
    description: string;
}

interface Value {
    itemId: number;
    amount: number;
    count: number;
}

interface Item {
    id: number;
    name: string;
}

interface ItemResponse {
    content: Item[],
    number: number,
    last: boolean,
    first: boolean
}

interface Receipt {
    id: string
    name: string
}

const CreateExpense = () => {
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [receipts, setReceipts] = useState<Receipt[]>([]);
    const [selectedReceipts, setSelectedReceipts] = useState<Receipt[]>([]);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [values, setValues] = useState<Value[]>([]);
    const [transactionId, setTransactionId] = useState<number>();
    const [startDate, setStartDate] = useState<Date>();
    const [sum, setSum] = useState<number>(0);
    const [items, setItems] = useState<Item[]>();
    const [amount, setAmount] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [itemId, setItemId] = useState<number>(0);
    const auth = useAuth();

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<ItemResponse>(`${API_URL}/api/expense/items?pageSize=2500&sortBy=name`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setItems(response.data.content);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });

        axios
            .get<Receipt[]>(`${API_URL}/api/receipts/todo`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                    // "Content-Type": "application/json",
                    // "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setReceipts(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    function setReceipt(receipt: Receipt): void {
        setSelectedReceipts([...selectedReceipts, receipt]);
    }

    function unsetReceipt(name: Receipt): void {
        setSelectedReceipts(selectedReceipts.filter(function(receipt) {
            return receipt !== name
        }));
    }
    function setTransactionGroup(id: number): void {
        setTransactionId(id);
    }

    function processFormSubmission(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        setLoading(true);

        // const { values, selectedReceipts, startDate } = this.state;

        const formData = {
            name: "test",
            transactionId: transactionId,
            date: startDate,
            values: values,
            value: sum,
            receipts: selectedReceipts
        }
        const access_token = auth.user?.access_token;

        console.log(formData);

        // @ts-ignore
        const bucket = startDate.getFullYear();
        // @ts-ignore
        const month = startDate.getMonth() + 1;

        console.log(bucket);
        console.log(month);

        axios
            .post(`${API_URL}/api/expense`, formData, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
                const expenseId = data.data.id;
                setSubmitSuccess(true);
                setLoading(false);
                // this.setState({ submitSuccess: true, loading: false, id: data.data.id })
                console.log(data);
                selectedReceipts.map(receipt => (
                        axios
                            .post(`${API_URL}/api/receipts/${receipt.id}/move?expenseId=${expenseId}`, {}, {
                                headers: {
                                    "Authorization": `Bearer ${access_token}`
                                },
                            })
                    )
                );
            })
    }

    function handleDateChanges(date: any): void {
        setStartDate(date);
        setTransactions([]);
        const access_token = auth.user?.access_token;

        const date2 = date.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'});
        axios
            .get(`${API_URL}/api/transactions/groups?from=${date2}&to=${date2}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
                console.log(data);
                setTransactions(data.data.content);
            })
    }

    function processItemFormSubmission(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        e.stopPropagation();

        setSum(sum + amount * count);
        const value: Value = {
            itemId: itemId,
            count: count,
            amount: amount
        };
        setValues([...values, value])
    }

    function handleInputChanges(e: React.FormEvent<HTMLInputElement>): void {
        e.preventDefault();
        if (e.currentTarget.name === "amount") {
            setAmount(parseInt(e.currentTarget.value));
        }
        if (e.currentTarget.name === "count") {
            setCount(parseInt(e.currentTarget.value));
        }
    }

    // function handleSelectChanges(e: React.FormEvent<HTMLSelectElement>): void {
    //     e.preventDefault();
    //     this.setState({
    //         [e.currentTarget.name]: e.currentTarget.value,
    //     })
    // }

    function handleSelectChanges2(e: IData): void {
        setItemId(e.id);
    }

    return (
        <div>
            <div className={"col-md-12 form-wrapper"}>
                {receipts && receipts.map(receipt =>
                    <p key={receipt.id} onClick={() => setReceipt(receipt)}>
                        {receipt.name}
                    </p>
                )}
            </div>
            <div className={"col-md-12 form-wrapper"}>
                <p>Wybrane:</p>
                {selectedReceipts && selectedReceipts.map(receipt =>
                    <p key={receipt.id}>
                        {/*{receipt}*/}
                        {/*<p>{receipt}</p>*/}
                        <p onClick={() => unsetReceipt(receipt)}>
                            {receipt.name}
                        </p>
                        <ReceiptImg receipt={receipt}/>
                    </p>
                )}
            </div>
            <div className={"col-md-12 form-wrapper"}>
                <h2> Create </h2>

                {!submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        Fill the form below to create a new item
                    </div>
                )}

                {submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        The form was successfully submitted!
                    </div>
                )}

                <table className="table table-bordered">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">Value</th>
                        <th scope="col">Date</th>
                        <th scope="col">Id</th>
                        <th scope="col">Description</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions && transactions.map(transaction =>
                        <tr key={transaction.id}>
                            <td>{transaction.value}</td>
                            <td>{transaction.date}</td>
                            <td>{transaction.id}</td>
                            <td>{transaction.description}</td>
                            <td>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="btn-group" style={{ marginBottom: "20px" }}>
                                        <button className="btn btn-sm btn-outline-secondary" onClick={() => setTransactionGroup(transaction.id)}>Set</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <form id={"create-item-form"} onSubmit={processFormSubmission} noValidate={true}>
                    <div className="form-group col-md-12">
                        <label htmlFor="date"> Date </label>
                        <DatePicker
                            name="date"
                            selected={startDate}
                            onChange={(date) => date && handleDateChanges(date)}
                            dateFormat="Y-MM-d"
                        />
                    </div>

                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Count</th>
                            <th scope="col">Sum</th>
                        </tr>
                        </thead>
                        <tbody>
                        {values && values.map(value =>
                            <tr key={value.itemId}>
                                <td>
                                    {
                                        items && items
                                            .filter(item => item.id === value.itemId)
                                            .map(filteredItem => (<span>{filteredItem.name}</span>))
                                    }
                                </td>
                                <td>{value.amount}</td>
                                <td>{value.count}</td>
                                <td>{value.count * value.amount}</td>
                            </tr>
                        )}
                        <tr>
                            <td>{sum}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div className="form-group col-md-4 pull-right">
                        <button className="btn btn-success" type="submit">
                            Create
                        </button>
                        {loading &&
                            <span className="fa fa-circle-o-notch fa-spin" />
                        }
                    </div>
                </form>
                <form id={"create-item-form"} onSubmit={processItemFormSubmission} noValidate={true}>
                    <div className="form-group col-md-12">
                        <label htmlFor="amount"> Amount </label>
                        <input
                            type="text"
                            id="amount"
                            onChange={(e) => handleInputChanges(e)}
                            name="amount"
                            className="form-control"
                            placeholder="Enter amount"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="count"> Count </label>
                        <input
                            type="text"
                            id="count"
                            onChange={(e) => handleInputChanges(e)}
                            name="count"
                            className="form-control"
                            placeholder="Enter count"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="itemId"> Item </label>
                        <AutoComplete
                            inputStyle={{ backgroundColor: "PaleTurquoise" }}
                            optionsStyle={{ backgroundColor: "LemonChiffon" }}
                            iconColor="Turquoise"
                            selected={handleSelectChanges2}
                        />
                    </div>

                    <div className="form-group col-md-4 pull-right">
                        <button className="btn btn-success" type="submit">
                            Create
                        </button>
                        {loading &&
                            <span className="fa fa-circle-o-notch fa-spin" />
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateExpense;