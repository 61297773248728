import * as React from 'react';
import axios from 'axios';
import {API_URL} from '../../../api/constants';
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface ExpenseGroup {
    id: number;
    name: string;
}

const CreateExpenseItem = () => {
    const [groups, setGroups] = useState<ExpenseGroup[]>([]);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [groupId, setGroupId] = useState<string>("");
    const auth = useAuth();

    function processFormSubmission(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        setLoading(true);

        const formData = {
            name: name,
            groupId: groupId
        }
        const access_token = auth.user?.access_token;

        axios
            .post(`${API_URL}/api/expense/items`, formData, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
                setSubmitSuccess(true);
                setLoading(false);
            })
    }

    function handleInputChanges(e: React.FormEvent<HTMLInputElement>): void {
        e.preventDefault();
        setName(e.currentTarget.value);
    }

    function handleSelectChanges(e: React.FormEvent<HTMLSelectElement>): void {
        e.preventDefault();
        setGroupId(e.currentTarget.value);
    }

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<ExpenseGroup[]>(`${API_URL}/api/expense/groups`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setGroups(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            <div className={"col-md-12 form-wrapper"}>
                <h2> Create </h2>
                {!submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        Fill the form below to create a new item
                    </div>
                )}

                {submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        The form was successfully submitted!
                    </div>
                )}

                <form id={"create-post-form"} onSubmit={processFormSubmission} noValidate={true}>
                    <div className="form-group col-md-12">
                        <label htmlFor="name"> Name </label>
                        <input
                            type="text"
                            id="name"
                            onChange={(e) => handleInputChanges(e)}
                            name="name"
                            className="form-control"
                            placeholder="Enter name"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="groupId"> Group </label>
                        <select
                            id="groupId"
                            onChange={(e) => handleSelectChanges(e)}
                            name="groupId"
                            className="form-control"
                        >
                            {groups && groups.map(group =>
                                <option key={group.id} value={group.id}>{group.name}</option>
                            )}
                        </select>
                    </div>

                    <div className="form-group col-md-4 pull-right">
                        <button className="btn btn-success" type="submit">
                            Create
                        </button>
                        {loading &&
                            <span className="fa fa-circle-o-notch fa-spin" />
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateExpenseItem;
