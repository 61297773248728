import React, {useState} from "react";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import {API_URL} from "../../api/constants";
import {useAuth} from "react-oidc-context";

interface TransactionMoveProps {
    groupId: string;
}

const TransactionMove = (props : TransactionMoveProps) => {
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<String>();
    const auth = useAuth();

    function handleInputChanges(e: React.ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        setTransactionId(e.currentTarget.value);
    }

    function processItemFormSubmission(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);

        const formData = {
            transactionId: transactionId,
        }
        const access_token = auth.user?.access_token;

        console.log(formData);
        console.log(props.groupId);

        axios
            .post(`${API_URL}/api/transactions/groups/${props.groupId}/transactions`, formData, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                },
            })
            .then(data => {
                setSubmitSuccess(true);
                setLoading(false);
                setTransactionId("");
            })
    }
    //
    // useEffect(() => {
    //     console.log(props);
    // });

    return (
        <div>
            <div className={"col-md-12 form-wrapper"}>
                <h2> Create </h2>

                {!submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        Fill the form below to create a new item
                    </div>
                )}

                {submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        The form was successfully submitted!
                    </div>
                )}

                <form id={"create-item-form"} onSubmit={processItemFormSubmission} noValidate={true}>
                    <div className="form-group col-md-12">
                        <label htmlFor="amount"> Transaction </label>
                        <input
                            type="text"
                            id="transactionId"
                            onChange={(e) => handleInputChanges(e)}
                            name="transactionId"
                            className="form-control"
                            placeholder="Enter transaction Id"
                        />
                    </div>

                    <div className="form-group col-md-4 pull-right">
                        <button className="btn btn-success" type="submit">
                            Create
                        </button>
                        {loading &&
                            <span className="fa fa-circle-o-notch fa-spin" />
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default TransactionMove;