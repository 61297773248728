import * as React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import {API_URL} from "../../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface Expense {
    id: number;
    date: string;
    value: number;
}

interface ExpenseResponse {
    content: Expense[],
    number: number,
    last: boolean,
    first: boolean
}

const ExpenseList = () => {
    const [expenses, setExpenses] = useState<Expense[]>([]);
    const auth = useAuth();

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<ExpenseResponse>(`${API_URL}/api/expense?pageSize=3000&sortBy=date`, {
                headers: {
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json",
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(response => {
                console.log(response);
                setExpenses(response.data.content);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {expenses.length === 0 && (
                <div className="text-center">
                    <h2>No expenses found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="btn-group" style={{ marginBottom: "20px" }}>
                    <Link to={`/expense/expensecreate/`} className="btn btn-sm btn-outline-secondary">Create</Link>
                </div>
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Date</th>
                            <th scope="col">Value</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {expenses && expenses.map(expense =>
                            <tr key={expense.id}>
                                <td>
                                    <Link to={`/expense/expense/${expense.id}`} className="btn btn-sm btn-outline-secondary">{expense.id}</Link>
                                </td>
                                <td>{moment(expense.date).format("YYYY-MM-DD")}</td>
                                <td>{expense.value}</td>
                                <td>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="btn-group" style={{ marginBottom: "20px" }}>
                                            {/*<Link to={`/expense/itemedit/${expense.id}`} className="btn btn-sm btn-outline-secondary">Edit</Link>*/}
                                            {/*    <button className="btn btn-sm btn-outline-secondary" onClick={() => this.deleteExpenseItem(expense.id)}>Delete</button>*/}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ExpenseList;
