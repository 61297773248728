import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import ReceiptImg from "./Img";

interface ReceiptList2Props {
    id: string;
    date: Date;
}

interface Receipt {
    id: string
    name: string
}

const ReceiptList2 = (props : ReceiptList2Props) => {
    const [receipts, setReceipts] = useState<Receipt[]>([]);
    const auth = useAuth();

    useEffect(() => {
        const access_token = auth.user?.access_token
        const a = new Date(props.date);
        // const a = props.date;
        const bucket = a.getFullYear();
        const month = a.getMonth() + 1;
        axios
            .get<Receipt[]>(`${API_URL}/api/receipts/buckets2/${props.id}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                    // "Content-Type": "application/json",
                    // "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setReceipts(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {receipts.length === 0 && (
                <div className="text-center">
                    <h2>Receipts found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {receipts && receipts.map(receipt => {
                                const a = new Date(props.date);
                                // const a = props.date;
                                const bucket = a.getFullYear().toString();
                                return <tr key={receipt.id}>
                                    <td>{receipt.name}</td>
                                    <td><ReceiptImg receipt={receipt}/></td>
                                </tr>
                            }
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ReceiptList2;
