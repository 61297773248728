import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface ExpenseDetailShowProps {
    id: string;
}

interface ExpenseDetailItem {
    id: number;
    name: string;
}

interface ExpenseDetail {
    id: number;
    expenseItem: ExpenseDetailItem;
    amount: number;
    count: number;
}

const ExpenseDetailShow = (props : ExpenseDetailShowProps) => {
    const [details, setDetails] = useState<ExpenseDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const auth = useAuth()

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<ExpenseDetail[]>(`${API_URL}/api/expense/${props.id}/details`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setDetails(response.data);
                setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, [props]);

    return (
        <div className="container">
            { !loading && (
                <div className="container">
                    <div className="row">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Item</th>
                                <th scope="col">Count</th>
                                <th scope="col">Amount</th>
                                <th scope="col">SUM</th>
                            </tr>
                            </thead>
                            <tbody>
                            {details.map(detail =>
                                <tr key={detail.id}>
                                    <td>{detail.id}</td>
                                    <td>{detail.expenseItem.name}</td>
                                    <td>{detail.count}</td>
                                    <td>{detail.amount / 10000}</td>
                                    <td>{(detail.amount / 10000) * detail.count}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}
export default ExpenseDetailShow;