import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {Link} from "react-router-dom";

interface Data {
    date: Date;
    created: Date;
    updated: Date;
    id: string;
    value: number;
    description: string;
}

interface DataResponse {
    data: Data[]
}

const Widget4 = () => {
    const [data, setData] = useState<Data[]>([]);
    const auth = useAuth()

    useEffect(() => {
        const access_token = auth.user?.access_token

        axios
            .get<DataResponse>(`${API_URL}/api/widgets4?from=2022-07-01&to=2022-12-31`, {
                headers: {
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json",
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(response => {
                console.log(response);
                setData(response.data.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {data.length === 0 && (
                <div className="text-center">
                    <h2>No groups found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Lp</th>
                            <th scope="col">Date</th>
                            <th scope="col">Created</th>
                            <th scope="col">Updated</th>
                            <th scope="col">Id</th>
                            <th scope="col">Value</th>
                            <th scope="col">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((group, index) =>
                            <tr>
                                <td>{index}</td>
                                <td>{group.date}</td>
                                <td>{group.created}</td>
                                <td>{group.updated}</td>
                                <td><Link to={`/transaction/group/${group.id}/transaction`} className="btn btn-sm btn-outline-secondary">{group.id}</Link></td>
                                <td>{group.value}</td>
                                <td>{group.description}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Widget4;