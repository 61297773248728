import { Link } from 'react-router-dom';
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../api/constants";
import TransactionGroupSearch from "./Search";
import {useAuth} from "react-oidc-context";

interface TransactionGroupListProps {
}

interface TransactionGroup {
    id: string;
    date: any;
    value: any;
    description: string;
}

interface TransactionGroupResponse {
    content: TransactionGroup[],
    number: number,
    last: boolean,
    first: boolean
}

const TransactionGroupList = (props : TransactionGroupListProps) => {
    const [groups, setGroups] = useState<TransactionGroup[]>([]);
    const [number, setNumber] = useState<number>(0);
    const [first, setFirst] = useState<boolean>(false);
    const [last, setLast] = useState<boolean>(false);
    const auth = useAuth();

    function page(pageNo: number): void {
        const access_token = auth.user?.access_token;
        axios
            .get<TransactionGroupResponse>(`${API_URL}/api/transactions/groups?pageSize=250&pageNo=${pageNo}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setGroups(response.data.content);
                setNumber(response.data.number);
                setFirst(response.data.first);
                setLast(response.data.last);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }

    function refresh(): void {
        const access_token = auth.user?.access_token;
        axios
            .post(`${API_URL}/api/old/fetch`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
            });
    }

    function processSearchSubmission (response: TransactionGroupResponse): void {
        setGroups(response.content);
        setNumber(response.number);
        setFirst(response.first);
        setLast(response.last);
    }

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<TransactionGroupResponse>(`${API_URL}/api/transactions/groups?pageSize=250`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setGroups(response.data.content);
                setNumber(response.data.number);
                setFirst(response.data.first);
                setLast(response.data.last);
                // setLoading(false);
            })
            .catch(ex => {
            const error =
                ex.response.status === 404
                    ? "Resource Not found"
                    : "An unexpected error has occurred";
            console.log(error);
            // setError(error);
            // setLoading(false);
            });
    }, []);

        return (
            <div>
                {groups.length === 0 && (
                    <div className="text-center">
                        <h2>No groups found at the moment</h2>
                    </div>
                )}

                <div className="container">
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => refresh()}>Refresh</button>
                </div>

                <div className="container">
                    <TransactionGroupSearch process={processSearchSubmission}/>
                    <div className="row">
                        {!first && (
                            <button className="btn btn-sm btn-outline-secondary" onClick={() => page(number-1)}>Prev page</button>
                        )}
                        {!last && (
                            <button className="btn btn-sm btn-outline-secondary" onClick={() => page(number+1)}>Next page</button>
                        )}
                        <table className="table table-bordered">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Date</th>
                                <th scope="col">Value</th>
                                <th scope="col">Description</th>
                                <th scope="col">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {groups && groups.map(group =>
                                <tr key={group.id}>
                                    <td>{group.id}</td>
                                    <td>{group.date}</td>
                                    <td>{group.value}</td>
                                    <td>{group.description}</td>
                                    <td>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-group" style={{marginBottom: "20px"}}>
                                                <Link to={`/transaction/group/${group.id}/transaction`}
                                                      className="btn btn-sm btn-outline-secondary">LIST</Link>
                                                <Link to={`/transaction/group/${group.id}/transaction/move`}
                                                      className="btn btn-sm btn-outline-secondary">MOVE</Link>
                                                <Link to={`/transaction/group/${group.id}/merge`}
                                                      className="btn btn-sm btn-outline-secondary">MERGE</Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {!first && (
                            <button className="btn btn-sm btn-outline-secondary" onClick={() => page(number-1)}>Prev page</button>
                        )}
                        {!last && (
                            <button className="btn btn-sm btn-outline-secondary" onClick={() => page(number+1)}>Next page</button>
                        )}
                    </div>
                </div>

            </div>
        )
    }
    export default TransactionGroupList;
