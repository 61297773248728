import * as React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {API_URL} from "../../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface ExpenseItemGroup {
    name: string
}

interface ExpenseItem {
    id: number;
    name: string;
    group: ExpenseItemGroup;
}

interface ExpenseItemListResponse {
    content: ExpenseItem[],
    number: number,
    last: boolean,
    first: boolean
}

const ExpenseItemList = () => {
    const [items, setItems] = useState<ExpenseItem[]>([]);
    const auth = useAuth();

    function deleteExpenseItem(id: number) {
        const access_token = auth.user?.access_token;
        axios
            .delete(`${API_URL}/api/expense/items/${id}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
                // const index = this.state.groups.findIndex(expenseGroup => expenseGroup.id === id);
                // this.state.groups.splice(index, 1);
            })
    }

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<ExpenseItemListResponse>(`${API_URL}/api/expense/items?pageSize=2500&sortBy=name`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setItems(response.data.content);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {items.length === 0 && (
                <div className="text-center">
                    <h2>No items found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="btn-group" style={{ marginBottom: "20px" }}>
                    <Link to={`/expense/itemcreate/`} className="btn btn-sm btn-outline-secondary">Create</Link>
                </div>
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Group</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items && items.map(item =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.group.name}</td>
                                <td>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="btn-group" style={{ marginBottom: "20px" }}>
                                            <Link to={`/expense/itemedit/${item.id}`} className="btn btn-sm btn-outline-secondary">Edit</Link>
                                            <button className="btn btn-sm btn-outline-secondary" onClick={() => deleteExpenseItem(item.id)}>Delete</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ExpenseItemList;