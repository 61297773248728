import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../../api/constants";
import moment from "moment/moment";
import TransactionList from "../../transactions/TransactionList";
import {useEffect, useState} from "react";
import ReceiptList2 from "../../receipts/List2";
import ExpenseDetailShow from "./Detail";
import {useAuth} from "react-oidc-context";

interface ExpenseShowProps {
    id: string;
}

interface Expense {
    date: Date;
    created: Date;
    updated: Date;
    value: number;
    transactionGroupId: string;
}

const ExpenseShow = (props : ExpenseShowProps) => {
    const [expense, setExpense] = useState<Expense>();
    const [loading, setLoading] = useState<boolean>(true);
    const auth = useAuth();

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<Expense>(`${API_URL}/api/expense/${props.id}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setExpense(response.data);
                setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div className="container">
            { !loading && (
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td>Date</td>
                            <td>{moment(expense!.date).format("YYYY-MM-DD")}</td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td>{moment(expense!.created).format("YYYY-MM-DD")}</td>
                        </tr>
                        <tr>
                            <td>Updated</td>
                            <td>{moment(expense!.updated).format("YYYY-MM-DD")}</td>
                        </tr>
                        <tr>
                            <td>Value</td>
                            <td>{expense!.value}</td>
                        </tr>
                        <tr>
                            <td>transactionGroupId</td>
                            <td>{expense!.transactionGroupId}</td>
                        </tr>
                        </tbody>
                    </table>
                    { expense!.transactionGroupId && <TransactionList groupId={expense!.transactionGroupId} />}
                    <ExpenseDetailShow id={props.id} />
                    <ReceiptList2 id={props.id} date={expense!.date} />
                </div>
            )}
        </div>
    )
}
export default ExpenseShow;

// <TransactionList groupId={expense!.transactionGroupId} />
// { !loading  && <ReceiptList groupId={props.id} date={expense.date} /> }