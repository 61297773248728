import * as React from 'react';
import axios from 'axios';
import {API_URL} from '../../../api/constants';
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface ExpenseGroup {
    id: number;
    name: string;
}

interface EditExpenseGroupProps {
    id: string;
}

const EditExpenseGroup = (props: EditExpenseGroupProps) => {
    const [expenseGroup, setExpenseGroup] = useState<ExpenseGroup>();
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const auth = useAuth();

    function processFormSubmission(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        setLoading(true);

        const formData = {
            name: name
        }
        const access_token = auth.user?.access_token;

        axios
            .put(`${API_URL}/api/expense/groups/${props.id}`, formData, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(response => {
                setSubmitSuccess(true);
                setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                setLoading(false);
            });
    }

    function handleInputChanges(e: React.FormEvent<HTMLInputElement>): void {
        e.preventDefault();
        setName(e.currentTarget.value);
    }

    useEffect((): void => {
        const access_token = auth.user?.access_token;
        axios
            .get<ExpenseGroup>(`${API_URL}/api/expense/groups/${props.id}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(response => {
                setExpenseGroup(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, [auth.user?.access_token, props.id]);

    return (
        <div>
            {expenseGroup &&
                <div>
                    < h1 > Customer List Management App</h1>
                    <p> Built with React.js and TypeScript </p>

                    <div>
                        <div className={"col-md-12 form-wrapper"}>
                            <h2> Edit Customer </h2>

                            {submitSuccess && (
                                <div className="alert alert-info" role="alert">
                                    Customer's details has been edited successfully </div>
                            )}

                            <form id={"create-post-form"} onSubmit={processFormSubmission} noValidate={true}>
                                <div className="form-group col-md-12">
                                    <label htmlFor="name"> Name </label>
                                    <input type="text" id="name" defaultValue={expenseGroup.name} onChange={(e) => handleInputChanges(e)} name="name" className="form-control" placeholder="Enter name" />
                                </div>

                                <div className="form-group col-md-4 pull-right">
                                    <button className="btn btn-success" type="submit">Edit</button>
                                    {loading &&
                                        <span className="fa fa-circle-o-notch fa-spin" />
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default EditExpenseGroup;
