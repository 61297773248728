import * as React from 'react';
import './App.css';
import {Route, Routes, useParams} from 'react-router-dom';
import {API_URL} from './api/constants';
import axios from 'axios';
import {Navbar} from "./components/Navbar";
import TransactionGroupList from "./components/transactions/List";
import TransactionMerge from "./components/transactions/Merge";
import TransactionList from "./components/transactions/TransactionList";
import TransactionMove from "./components/transactions/Move";
import ReceiptList from "./components/receipts/List";
import ReceiptMove from "./components/receipts/Move";
import ExpenseItemList from "./components/expenses/items/List";
import CreateExpenseItem from "./components/expenses/items/Create";
import EditExpenseItem from "./components/expenses/items/Edit";
import ExpenseGroupList from "./components/expenses/groups/List";
import CreateExpenseGroup from "./components/expenses/groups/Create";
import EditExpenseGroup from "./components/expenses/groups/Edit";
import ExpenseList from "./components/expenses/expenses/List";
import CreateExpense from "./components/expenses/expenses/Create";
import ExpenseShow from "./components/expenses/expenses/Show";
import Widget1 from "./components/widget/Widget1";
import Widget2 from "./components/widget/Widget2";
import Widget3 from "./components/widget/Widget3";
import Widget4 from "./components/widget/Widget4";
import PrivateRoute from "./components/PrivateRoute";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['X-API-VERSION'] = '0.1';

const TransactionListWrapper = () => {
    const { groupId } = useParams() as {
        groupId: string;
    }
    return <TransactionList groupId={groupId} />;
};
const TransactionMoveWrapper = () => {
    const { groupId } = useParams() as {
        groupId: string;
    }
    return <TransactionMove groupId={groupId} />;
};
const TransactionMergeWrapper = () => {
    const { groupId } = useParams() as {
        groupId: string;
    }
    return <TransactionMerge groupId={groupId} />;
};

const ReceiptMoveWrapper = () => {
    const { receipt } = useParams() as unknown as {
        receipt: number;
    }
    return <ReceiptMove receipt={receipt} />;
};
const EditExpenseItemWrapper = () => {
    const { id } = useParams() as {
        id: string;
    }
    return <EditExpenseItem id={id} />;
};
const EditExpenseGroupWrapper = () => {
    const { id } = useParams() as {
        id: string;
    }
    return <EditExpenseGroup id={id} />;
};

const ExpenseShowWrapper = () => {
    const { id } = useParams() as {
        id: string;
    }
    return <ExpenseShow id={id} />;
};

const App = () => {
    return (
        <div className="App">
            <PrivateRoute>
                <Navbar/>
                <Routes>
                    <Route path="/transaction/group" element={<TransactionGroupList/>}/>
                    <Route path="/transaction/group/:groupId/merge" element={<TransactionMergeWrapper/>} />
                    <Route path="/transaction/group/:groupId/transaction/move" element={<TransactionMoveWrapper/>} />
                    <Route path="/transaction/group/:groupId/transaction" element={<TransactionListWrapper/>} />
                    <Route path="/receipts" element={<ReceiptList />} />
                    <Route path="/receipts/:receipt/move" element={<ReceiptMoveWrapper />} />
                    <Route path="/expense/item" element={<ExpenseItemList />} />
                    <Route path="/expense/itemcreate" element={<CreateExpenseItem />} />
                    <Route path="/expense/itemedit/:id" element={<EditExpenseItemWrapper />} />
                    <Route path="/" element={<ExpenseGroupList />} />
                    <Route path="/expense/group" element={<ExpenseGroupList />} />
                    <Route path="/expense/groupcreate" element={<CreateExpenseGroup />} />
                    <Route path="/expense/groupedit/:id" element={<EditExpenseGroupWrapper />} />
                    <Route path="/expense/expense" element={<ExpenseList />} />
                    <Route path="/expense/expensecreate" element={<CreateExpense />} />
                    <Route path="/expense/expense/:id" element={<ExpenseShowWrapper />} />
                    <Route path="/widget1" element={<Widget1 />} />
                    <Route path="/widget2" element={<Widget2 />} />
                    <Route path="/widget3" element={<Widget3 />} />
                    <Route path="/widget4" element={<Widget4 />} />
                </Routes>
            </PrivateRoute>
        </div>
    )
};

export default App;
