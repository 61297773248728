import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../api/constants";
import {useState} from "react";
import {useAuth} from "react-oidc-context";

interface TransactionMergeProps {
    groupId: string;
}

const TransactionMerge = (props : TransactionMergeProps) => {
    const [source, setSource] = useState<String>();
    const auth = useAuth();

    function handleInputChanges(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setSource(e.currentTarget.value);
    }

    function processItemFormSubmission(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();

        const formData = {
            id: source,
        }
        const access_token = auth.user?.access_token;

        console.log(formData);
        console.log(props.groupId);

        axios
            .post(`${API_URL}/api/transactions/groups/${props.groupId}/merge`, formData, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                },
            })
            .then(data => {
                setSource("");
            })
    }

    return (
        <div>
            <div className={"col-md-12 form-wrapper"}>
                <h2> Merge </h2>

                <form id={"merge-form"} onSubmit={processItemFormSubmission} noValidate={true}>
                    <div className="form-group col-md-12">
                        <label htmlFor="transactionGroupId"> Transaction group </label>
                        <input
                            type="text"
                            id="transactionGroupId"
                            onChange={(e) => handleInputChanges(e)}
                            name="transactionGroupId"
                            className="form-control"
                            placeholder="Enter transaction group id"
                        />
                    </div>

                    <div className="form-group col-md-4 pull-right">
                        <button className="btn btn-success" type="submit">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default TransactionMerge;