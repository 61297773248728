import * as React from 'react';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {API_URL} from "../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
    }
};

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Widget1 = () => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const auth = useAuth()
    const [from, onChangeFrom] = useState<Value>(new Date("2024-01-01"));
    const [to, onChangeTo] = useState<Value>(new Date("2024-12-31"));

    useEffect(() => {
        const access_token = auth.user?.access_token
        // @ts-ignore
        var fromYear = from.toLocaleString("default", { year: "numeric" });
        // @ts-ignore
        var fromMonth = from.toLocaleString("default", { month: "2-digit" });
        // @ts-ignore
        var fromDay = from.toLocaleString("default", { day: "2-digit" });
        var formattedFrom = fromYear + "-" + fromMonth + "-" + fromDay;
        // @ts-ignore
        var toYear = to.toLocaleString("default", { year: "numeric" });
        // @ts-ignore
        var toMonth = to.toLocaleString("default", { month: "2-digit" });
        // @ts-ignore
        var toDay = to.toLocaleString("default", { day: "2-digit" });
        var formattedTo = toYear + "-" + toMonth + "-" + toDay;

        axios
            .get<[]>(`${API_URL}/api/widgets?from=${formattedFrom}&to=${formattedTo}`, {
                headers: {
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json",
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(response => {
                console.log(response);
                // @ts-ignore
                const labels = response.data.data.map(item => item.date).filter((value, index, self) => self.indexOf(value) === index)
                // @ts-ignore
                const names = response.data.data.map(item => item.name).filter((value, index, self) => self.indexOf(value) === index)
                // @ts-ignore
                let datasets = names.map(element => {
                    const container = {};

                    // @ts-ignore
                    container.label = element;
                    // @ts-ignore
                    container.data = labels.map(element2 => {
                        // @ts-ignore
                        let asd = response.data.data.filter(element3 => {
                            return element3.name === element;
                            // @ts-ignore
                        }).filter(element3 => {
                            return element3.date === element2;
                        })
                        if (asd.length > 0) {
                            return asd[0].value;
                        }
                        return 0;
                    });

                    // @ts-ignore
                    container.backgroundColor = '#' + (Math.random().toString(16) + '0000000').slice(2, 8);

                    return container;
                });

                const data = {
                    labels: labels,
                    datasets: datasets
                }

                setData(data);
                setLoading(false);
            })
            .catch(ex => {
                console.log(ex);
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, [from, to]);

    return (
        <div>
            {data.length === 0 && (
                <div className="text-center">
                    <h2>No groups found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <Calendar onChange={onChangeFrom} value={from} />
                    <Calendar onChange={onChangeTo} value={to} />
                </div>
            </div>

            { !loading && (
                <div className="container">
                    <div className="row">
                        <Bar options={options} data={data} />;
                    </div>
                </div>
            )}
        </div>
    )
}
export default Widget1;