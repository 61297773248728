import * as React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {API_URL} from '../../../api/constants';
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface ExpenseGroup {
    id: number;
    name: string;
}

const ExpenseGroupList = () => {
    const [groups, setGroups] = useState<ExpenseGroup[]>([]);
    const auth = useAuth();

    function deleteExpenseGroup(id: number) {
        const access_token = auth.user?.access_token;
        axios
            .delete(`${API_URL}/api/expense/groups/${id}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
            // const index = this.state.groups.findIndex(expenseGroup => expenseGroup.id === id);
            // this.state.groups.splice(index, 1);
        })
    }

    useEffect(() => {
        const access_token = auth.user?.access_token;
        axios
            .get<ExpenseGroup[]>(`${API_URL}/api/expense/groups`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setGroups(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {groups.length === 0 && (
                <div className="text-center">
                    <h2>No groups found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="btn-group" style={{ marginBottom: "20px" }}>
                    <Link to={`/expense/groupcreate`} className="btn btn-sm btn-outline-secondary">Create</Link>
                </div>
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {groups && groups.map(group =>
                            <tr key={group.id}>
                                <td>{group.id}</td>
                                <td>{group.name}</td>
                                <td>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="btn-group" style={{ marginBottom: "20px" }}>
                                            <Link to={`/expense/groupedit/${group.id}`} className="btn btn-sm btn-outline-secondary">Edit</Link>
                                            <button className="btn btn-sm btn-outline-secondary" onClick={() => deleteExpenseGroup(group.id)}>Delete</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ExpenseGroupList;
