import * as React from 'react';
import axios from 'axios';
import {API_URL} from "../../api/constants";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface TransactionListProps {
    groupId: string;
}

interface Transaction {
    id: string;
    date: any;
    value: any;
    description: string;
}

const TransactionList = (props : TransactionListProps) => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const auth = useAuth();

    useEffect(() => {
        const access_token = auth.user?.access_token;
        console.log(props);
        axios
            .get<Transaction[]>(`${API_URL}/api/transactions/groups/${props.groupId}/transactions`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    // "Content-Type": "application/json",
                    "Accept": "application/hal+json"
                },
            })
            .then(response => {
                console.log(response);
                setTransactions(response.data);
                // setLoading(false);
            })
            .catch(ex => {
                console.log(ex);
                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                console.log(error);
                // setError(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            {transactions.length === 0 && (
                <div className="text-center">
                    <h2>No groups found at the moment</h2>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <table className="table table-bordered">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Date</th>
                            <th scope="col">Value</th>
                            <th scope="col">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {transactions && transactions.map(group =>
                            <tr key={group.id}>
                                <td>{group.id}</td>
                                <td>{group.date}</td>
                                <td>{group.value}</td>
                                <td>{group.description}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default TransactionList;
