import React, {useState} from "react";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import {API_URL} from "../../api/constants";
import {useAuth} from "react-oidc-context";

interface ReceiptMoveProps {
    receipt: number;
}

const ReceiptMove = (props : ReceiptMoveProps) => {
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [expenseId, setExpenseId] = useState<string>();
    const auth = useAuth();

    function handleInputChanges(e: React.ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        setExpenseId(e.currentTarget.value);
    }

    function processItemFormSubmission(e: React.FormEvent<HTMLFormElement>): void {
        const access_token = auth.user?.access_token;
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        setSubmitSuccess(false);

        axios
            .get(`${API_URL}/api/expense/${expenseId}`, {
                headers: {
                    "Authorization": `Bearer ${access_token}`
                },
            })
            .then(data => {
                console.log(data);
                const receipt = props.receipt;

                console.log(receipt);

                const a = new Date(data.data.date);
                const dstBucket = a.getFullYear();
                const dstMonth = a.getMonth() + 1;

                console.log(a)
                console.log(dstMonth)
                console.log(dstBucket)
                axios
                    .post(`${API_URL}/api/receipts/${receipt}/move?expenseId=${expenseId}`, {}, {
                        headers: {
                            "Authorization": `Bearer ${access_token}`
                        },
                    })
            })
    }

    return (
        <div>
            <div className={"col-md-12 form-wrapper"}>
                <h2> Create </h2>

                {!submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        Fill the form below to create a new item
                    </div>
                )}

                {submitSuccess && (
                    <div className="alert alert-info" role="alert">
                        The form was successfully submitted!
                    </div>
                )}

                <form id={"create-item-form"} onSubmit={processItemFormSubmission} noValidate={true}>
                    <div className="form-group col-md-12">
                        <label htmlFor="amount"> Transaction </label>
                        <input
                            type="text"
                            id="expenseId"
                            onChange={(e) => handleInputChanges(e)}
                            name="expenseId"
                            className="form-control"
                            placeholder="Enter expense Id"
                        />
                    </div>

                    <div className="form-group col-md-4 pull-right">
                        <button className="btn btn-success" type="submit">
                            Create
                        </button>
                        {loading &&
                            <span className="fa fa-circle-o-notch fa-spin" />
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ReceiptMove;
